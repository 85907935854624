import React, { useEffect, useState } from "react"
import LoadIndustryPopup from "./loadIndustryPopup"
import { save } from "../AboutYourBusiness/helper"

const SettingImport = ({ allowClonePassword, clonePassword }) => {
  const [importSetting, setImportSetting] = useState(false),
    [cloneAccount, setCloneAccount] = useState(false),
    [cloneSwitch, setCloneSwitch] = useState("On"),
    [cloneSwitchId, setCloneSwitchId] = useState(null),
    [password, setPassword] = useState(""),
    [passwordId, setPasswordId] = useState(null),
    [industryModal, setIndustryModal] = useState(false)

  const handleSwitch = e => {
    save({
      id: cloneSwitchId,
      type: "AllowCloneAccount",
      value: e.target.checked ? "On" : "Off",
      setValue: setCloneSwitch,
      setId: setCloneSwitchId,
    })
  }

  const handlePassword = () => {
    if (password !== "") {
      save({
        id: passwordId,
        type: "ClonePassword",
        value: password,
        setValue: setPassword,
        setId: setPasswordId,
      })
    }
  }

  useEffect(() => {
    if (cloneSwitchId === null && allowClonePassword) {
      setCloneSwitchId(allowClonePassword.id)
      setCloneSwitch(allowClonePassword.value)
    }

    if (passwordId === null && clonePassword) {
      setPasswordId(clonePassword.id)
      setPassword(clonePassword.value)
    }
  })

  return (
    <div className="accordion-item">
      <h2
        className="accordion-header accordion-button collapsed"
        data-bs-toggle="collapse"
        data-bs-target="#settingsImportExportSync"
        aria-expanded="false"
        aria-controls="settingsImportExportSync"
      >
        <i className="bi bi-gear-fill"></i>
        Settings Import / Export / Sync
      </h2>
      <div
        id="settingsImportExportSync"
        className="accordion-collapse collapse"
        aria-labelledby="settingsImportExportSync"
        data-bs-parent="#advanced"
      >
        <div className="accordion-body px-3">
          <div className="row g-0">
            <div className="col-lg-12 text-center">
              <div className="alert alert-warning" role="alert">
                <i className="bi bi-exclamation-triangle-fill"></i>
                Warning: This will overwrite settings on this page. See{" "}
                <a href="https://swiftcrm.com/support/settings-sync" target="_blank">
                  Settings Import / Sync
                </a>{" "}
                for more details.
              </div>
            </div>
            <div className="col-lg-12 text-left mb-3">
              <button className="btn btn-secondary" onClick={() => setIndustryModal(true)}>
                <i className="bi bi-briefcase-fill"></i> Load Industry Presets
              </button>{" "}
              <button className="btn btn-default">
                <i className="bi bi-download"></i> Export Settings
              </button>{" "}
              <button
                className="btn btn-default btnImportUserSettings"
                onClick={() => setImportSetting(!importSetting)}
              >
                <i className="bi bi-upload"></i> Import Settings
              </button>{" "}
              <button
                className="btn btn-default btnCloneFromAccount"
                onClick={() => setCloneAccount(!cloneAccount)}
              >
                <i className="bi bi-cloud-arrow-down-fill"></i> Clone from Account
              </button>{" "}
              {importSetting == true && (
                <div className="row px-3 importUserSettingsRow my-3">
                  <div className="col-lg-5 mx-auto">
                    <form id="importUserSettingsForm" encType="multipart/form-data" method="POST">
                      <div className="row">
                        <div className="col-lg-12 colHeading">
                          <strong>
                            <i className="bi bi-box-arrow-in-right"></i> Import Settings
                          </strong>
                        </div>
                        <div className="col-lg-12 dh-block-body">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="input-group mb-2">
                                <input
                                  type="file"
                                  id="uploadImportSettingCsvFile"
                                  name="csv_setting_file"
                                  className="form-control"
                                />
                                <button type="button" id="importProceedButton" className="btn btn-default">
                                  <i className="bi bi-cloud-arrow-up-fill"></i> Upload
                                </button>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <button
                                type="button"
                                className="btn btn-danger btnCancelImportUserSettings"
                                onClick={() => setImportSetting(false)}
                              >
                                <i className="bi bi-x-lg"></i> Cancel
                              </button>{" "}
                              <button
                                type="button"
                                className="btn btn-secondary submitImportUserSettingsButton"
                              >
                                <i className="bi bi-upload"></i> Proceed &amp; Overwrite
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              {cloneAccount == true && (
                <div className="row px-3 cloneFromAccountRow my-3">
                  <div className="col-lg-5 mx-auto">
                    <form id="cloneFromAccountForm" encType="multipart/form-data" method="POST">
                      <div className="row">
                        <div className="col-lg-12 colHeading">
                          <strong>
                            <i className="bi bi-cloud-arrow-down-fill"></i> Clone from Account
                          </strong>
                        </div>
                        <div className="col-lg-12 dh-block-body">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="input-group mb-2">
                                <span className="input-group-text">Account Number:</span>
                                <input
                                  type="text"
                                  id="clone_account_no"
                                  name="clone_account_no"
                                  className="form-control"
                                />
                                <button
                                  type="button"
                                  className="btn btn-default clone_account_confirm_button"
                                >
                                  <i className="bi bi-box-arrow-in-right"></i> Import Assets &amp; Settings
                                </button>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <button
                                type="button"
                                className="btn btn-danger mt-2 btnCancelCloneFromAccount"
                                onClick={() => setCloneAccount(false)}
                              >
                                <i className="bi bi-x-lg"></i> Cancel
                              </button>{" "}
                              <button
                                type="button"
                                className="btn btn-secondary mt-2 submitCloneFromAccountButton"
                              >
                                <i className="bi bi-upload"></i> Proceed &amp; Overwrite
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>

            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <div className="toggleSwitch">
                    <input
                      type="checkbox"
                      name="allow_to_clone_account"
                      id="allow_to_clone_account"
                      key={cloneSwitch}
                      defaultChecked={cloneSwitch === "On"}
                      onChange={handleSwitch}
                    />
                    <label>
                      Allow others to clone this account {localStorage.getItem("user_id")}
                      <a href="http://swiftcrm.com/support/clone-account" target="_blank">
                        <i className="bi bi-question-circle-fill"></i>
                      </a>
                    </label>
                  </div>
                </div>
                {cloneSwitch === "On" && (
                  <div className="col-lg-6 mt-2 allow_to_clone_account_password">
                    <div className="row row-cols-lg-auto g-1 align-items-center">
                      <div className="col-12">Password:</div>
                      <div className="col-12">
                        <input
                          type="text"
                          name="clone_account_password"
                          id="clone_account_password"
                          className="form-control"
                          defaultValue={password}
                          onChange={e => {
                            setPassword(e.target.value)
                          }}
                          onBlur={handlePassword}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadIndustryPopup industryModal={industryModal} setIndustryModal={setIndustryModal} />
    </div>
  )
}

export default SettingImport
