import React, { useEffect, useState } from "react"
import SettingsLayout from "./settingsLayout"
import SettingImport from "./Advanced/settingImport"
import TrustedSender from "./Advanced/trustedSender"
import AxiosInstance from "../../services/axiosInstance"

const Advanced = () => {
  const [allowClonePassword, setAllowClonePassword] = useState(""),
    [clonePassword, setClonePassword] = useState("")

  const getAdvancedSettings = async () => {
    AxiosInstance.get("/settings/general-setting/advance-data").then(response => {
      response.data.data.map(data => {
        if (data.type === "AllowCloneAccount") {
          setAllowClonePassword(data)
        }
        if (data.type === "ClonePassword") {
          setClonePassword(data)
        }
      })
    })
  }

  useEffect(() => {
    getAdvancedSettings()
  }, [])

  return (
    <SettingsLayout title="Advanced" iconClass="gear-fill me-2" isBusinessPage="1">
      <div className="row g-0 pt-5">
        <div className="col">
          <div className="accordion" id="SwiftCloudSettings">
            <div className="accordion-item">
              <h2
                className="accordion-header accordion-button"
                data-bs-toggle="collapse"
                data-bs-target="#advanced"
                aria-expanded="true"
                aria-controls="advanced"
              >
                <i className="bi bi-gear-fill me-2" /> Advanced
              </h2>
              <div
                id="advanced"
                className="accordion-collapse collapse show"
                aria-labelledby="advanced"
                data-bs-parent="#SwiftfCloudSettings"
              >
                <div className="accordion-body p-0">
                  <SettingImport allowClonePassword={allowClonePassword} clonePassword={clonePassword} />
                  <TrustedSender />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SettingsLayout>
  )
}

export default Advanced
