import React, { useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const TrustedSender = () => {
  const [allowMsgValue, setAllowMsgValue] = useState("On")

  const handleAllowMsg = e => {
    setAllowMsgValue(e.target.checked ? "On" : "Off")
  }

  return (
    <div className="accordion-item">
      <h2
        className="accordion-header accordion-button"
        data-bs-toggle="collapse"
        data-bs-target="#trustedSenderMessageRelay"
        aria-expanded="true"
        aria-controls="trustedSenderMessageRelay"
      >
        <i className="bi bi-gear-fill"></i>
        Trusted Sender Message Relay
      </h2>
      <div
        id="trustedSenderMessageRelay"
        className="accordion-collapse collapse"
        aria-labelledby="trustedSenderMessageRelay"
        data-bs-parent="#advanced"
      >
        <div className="accordion-body px-3">
          <div className="row g-0">
            <div className="col-lg-12 text-left mb-3">
              <div className="row row-cols-lg-auto g-3 align-items-center">
                <div className="col-12">
                  Allow Message Piping &amp; Remote Send-Through to Contacts &amp; Groups / Audiences{" "}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        Remote account cannot see your contacts, but can email and/or SMS to your contacts and
                        lists. Recipients will be de-duplicated at time of sending.
                      </Tooltip>
                    }
                  >
                    <span>
                      <i className="bi bi-question-circle-fill"></i>
                    </span>
                  </OverlayTrigger>
                </div>
                <div className="col-12">
                  <div className="toggleSwitch">
                    <input
                      type="checkbox"
                      defaultValue={"On"}
                      key={allowMsgValue}
                      defaultChecked={allowMsgValue == "On"}
                      onChange={handleAllowMsg}
                      name="AllowMessagePiping"
                      id="AllowMessagePiping"
                    />
                    <label></label>
                  </div>
                </div>
                {allowMsgValue === "On" && (
                  <div className="col-12 messagePipingInviteUser">
                    <OverlayTrigger placement="top" overlay={<Tooltip>Invite User</Tooltip>}>
                      <button className="btn btn-sm btnInviteUser">
                        <i className="bi bi-person-plus-fill"></i>
                      </button>
                    </OverlayTrigger>{" "}
                    <ul className="user-card-list">
                      <li>
                        <a href="" className="btnRemoveUser">
                          <i className="bi bi-x-lg"></i>
                        </a>
                        <a href="contact_detail.html">
                          <img src="images/susan.jpg" alt="" className="user_avatar" />
                          Marvin Lee, MD
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrustedSender
