import React from "react"
import { Modal } from "react-bootstrap"

const LoadIndustryPopup = ({ industryModal, setIndustryModal }) => {
  return (
    <Modal show={industryModal} onHide={() => setIndustryModal(false)}>
      <Modal.Header>
        <h1 className="modal-title fs-5" id="industryPresetsModalLabel">
          <i className="bi bi-briefcase-fill"></i> Load Industry Presets
        </h1>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => setIndustryModal(false)}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-lg-12">
            <select className="form-select">
              <option value="Real Estate">Real Estate</option>
              <option value="Recruiting / HR">Recruiting / HR</option>
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-default"
          data-bs-dismiss="modal"
          onClick={() => setIndustryModal(false)}
        >
          <i className="bi bi-x-lg"></i> Close
        </button>{" "}
        <button type="button" className="btn btn-primary btnAdvSettingImport">
          <i className="bi bi-check2"></i> Import &amp; Overwrite
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default LoadIndustryPopup
