import React from "react"
import AdvancedDetails from "../../components/Settings/advanced"
import PrivateRoute from "../../components/privateRoute"
import ReduxWrapper from "../../redux/reduxWrapper"

const Advanced = props => {
  return <PrivateRoute component={AdvancedDetails} location={props.location} />
}
const WrappedPage = props => <ReduxWrapper element={<Advanced {...props} />} />

export default WrappedPage
